body::-webkit-scrollbar {
    display: none;
}
.mt--6 {
    margin-top: -6px
}
.subData {
display: none;
opacity: 0;
z-index: 1;
transition: 0.2s ease-in-out;
}

.d-none {
    display: initial !important;
}

 .Level2Name {
     font-size: 10px;

 }

 .css-oalq6z:last-of-type::before {
    border-radius: 0;
 }
 .css-oalq6z:first-of-type::after {
    border-radius: 0;
 }

.styleNode {
    background: #fff;
    /* box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1); */
    display: inline-block;
    border-radius: 8px;
    width: 100px;
    height: 100px;

    z-index: inherit;

}

.styleNode p {
    margin:  0;
}

.styleNode img {
    height: 55px !important;
    width: 55px !important;
}

.styleNode svg {
    font-size: 25px;
    opacity: .75;
    position: absolute;
    top: 0;
    right: 0;
}
.styleNode-2 svg {
    font-size: 25px;
    opacity: .75;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 45%;
   
}
.styleNode-3 svg {
    font-size: 25px;
    opacity: .75;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 38%;
}
.styleNode-4 svg {
    font-size: 25px;
    opacity: .75;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 34%;
}
.styleNode-5 svg {
    font-size: 15px;
    opacity: .75;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -20px;
}

.styleNode-2 {
    background: #fff;
    position: relative;
    display: inline-block;
    border-radius: 8px;
    width: 85px;
    height: 85px;
    cursor: pointer;

    z-index: 999 !important;
}

.styleNode-2 img {
    height: 40px !important;
    width: 40px !important;
}

.styleNode-3 {
    background: #fff;
 
    display: inline-block;
    border-radius: 8px;
    width: 75px;
    height: 75px;
}

.styleNode-3 b {
    margin-top: -15px;
}

.level3Name {
    font-size: 8px ;
}

.styleNode-3 .user-name {
    font-size: 10px
}

.styleNode-3 img {
    height: 40px !important;
    width: 40px !important;
}

.styleNode-4 {
    background: #fff;
 
    display: inline-block;
    border-radius: 8px;
    width: 65px;
    height: 65px;
}

.styleNode-4 b {
    margin-top: -15px;
}

.styleNode-4 img {
    height: 30px !important;
    width: 30px !important;
}

.styleNode-5 {
    background: #fff;
  
    display: inline-block;
    border-radius: 8px;
    width: 45px;
    height: 45px;
    /* font-size: 15px !important;
    -webkit-font-size: 25px !important; */
}

.styleNode-5 .level5Name {
    font-size: 7px !important;
}


.styleNode-5 img {
    height: 30px !important;
    width: 30px !important;
}

.styleNode:hover + .subData {
       top: 30px;
        font-size: 16px;
            opacity: 1;
            line-height: .75rem;
        padding: 10px;
        padding-bottom: 15px;
        margin-bottom: 45px !important;
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 320px;
        display: inline-block;
        position: fixed;
        background: #fffffff6;
        box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: 1px solid #000000;
        z-index: 9999;
}

.styleNode-2:hover + .subData {
        top: 30px;
        font-size: 16px;
        opacity: 1;
        line-height: .75rem;
        padding: 10px;
        padding-bottom: 20px;
        margin-bottom: 45px !important;
        display: inline-block;
        flex-direction: column;
        width: 320px;
        height: 320px;
        border-radius: 5px;
        border: 1px solid #000000;
        position: fixed;
        background: #fffffff6;
        box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
        z-index: 9999;
}

.styleNode-3:hover + .subData {
        top: 30px;
        font-size: 16px;
        opacity: 1;
        line-height: .75rem;
        padding: 10px;
        padding-bottom: 15px;
        margin-bottom: 45px !important;
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 320px;
        display: inline-block;
        /* position: absolute; */
        position: fixed;
        background: #fffffff6;
        box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: 1px solid #000000;
        z-index: 9999;
}

.styleNode-4:hover+.subData {
        top: 30px;
        font-size: 16px;
        opacity: 1;
        line-height: .75rem;
        padding: 10px;
        padding-bottom: 15px;
        margin-bottom: 45px !important;
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 320px;
        display: inline-block;
        /* position: absolute; */
        position: fixed;
        background: #fffffff6;
        box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: 1px solid #000000;
        z-index: 9999;
}

.styleNode-5:hover + .subData {
            top: 30px;
            font-size: 16px;
            opacity: 1;
            line-height: .75rem;
            padding: 10px;
            padding-bottom: 15px;
            margin-bottom: 45px !important;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            width: 320px;
            height: 320px;
            display: inline-block;
            position: fixed;
            background: #fffffff6;
            box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            border: 1px solid #000000;
            z-index: 9999;

        -webkit-backface-visibility: hidden;
            -webkit-transform: translateX(0);
       
}


@media screen and (max-width: 1024px) {

    .container {
        margin-top: 100px;
    }
    .styleNode {
        padding: 3px;
        
        display: inline-block;
        border-radius: 8px;
        width: 100px;
        height: 100px;
    }
   
    .styleNode-2 {
        padding: 2px;
       
        display: inline-block;
        border-radius: 8px;
        width: 80px;
        height: 80px;
    }
    .styleNode-2 b {
        margin-top: -4px;
    }

    .styleNode-2 img {
        margin-top: -2px;
        height: 45px !important;
        width: 45px !important;
    }

    .styleNode-3 {
        padding: 2px;
       
        display: inline-block;
        border-radius: 8px;
        width: 70px;
        height: 70px;
    }
  
    .styleNode-3 img {
        height: 40px !important;
        width: 40px !important;
    }

    .styleNode-4 {
        padding: 1px;
 
        display: inline-block;
        border-radius: 8px;  
        width: 60px;
        height: 60px;
    }

    .styleNode-4 img {
        height: 30px !important;
        width: 30px !important;
    }

    .styleNode-5 {
        padding: 1px;

        display: inline-block;
        border-radius: 8px;
        width: 30px;
        height: 30px;
    }

    .styleNode-5 b{
        font-size: 6px !important;
    }

    .styleNode-5 p {
        display: none;
    }

        .styleNode-5 .level5Name {
            opacity: 0 !important;
            font-size: 3px !important;
        }

    .styleNode-5 img {
        height: 20px !important;
        width: 20px !important;
        margin-top: -12px;
    }
}

@media screen and (max-width: 768px) {

        .container {
                margin-top: 50px;
            }

    .d-none {
        display: none;
    }

    .styleNode {
        padding: 5px;
       
        display: inline-block;
        border-radius: 8px;
        width: 100px;
        height: 100px;
    }

    .styleNode  b {
        margin-top: -15px !important;
    }

    .styleNode p {
        margin-bottom: 0 !important;
    }

    .styleNode-2 {
        padding: 5px;
        
        
        display: inline-block;
        border-radius: 8px;
        width: 80px;
        height: 80px;
      
        position: relative;
        z-index: 99 !important;
    }

    .Level2Name {
        font-size: 8px;

    }


    .styleNode-2 img {
        height: 45px !important;
        width: 45px !important;
    }

    .styleNode-3 {
  
        display: inline-block;
        border-radius: 8px;
        width: 65px;
        height: 65px;

        position: relative;
        z-index: 99 !important;
    }

    .styleNode-3 img {
        height: 35px !important;
        width: 35px !important;
    }

    .styleNode-4 {
  
        display: inline-block;
        border-radius: 8px;
        width: 42px;
        height: 42px;

        position: relative;
        z-index: 99 !important;
    }
    .styleNode-4 b {
        margin-top: 5px;
    }

    .styleNode-4 p {
            /* display: none */
            /* opacity: 0 !important; */
            font-size: 5px !important;
            margin: 0px ;
            margin-top: -3px;
         
    }

    .styleNode-4 img {
        height: 25px !important;
        width: 25px !important;
        margin-top: -10px;
    }

    .styleNode-4 svg {
margin-bottom: 10px !important;
margin-left: 10px !important;
    }

    .styleNode-5 {
     
        display: inline-block;
        border-radius: 8px;
        width: 20px;
        height: 20px;

        position: relative;
        z-index: 99 !important;

    }



    .styleNode-5 svg {
        font-size: 16px;
        margin-top: -7px !important;
    }

    .styleNode-5 img {
        height: 20px !important;
        width: 20px !important;
        margin-bottom: 7px;
        margin-left: 1px;
        margin-top: 15px
        /* margin-bottom: 15px; */
     
    }
.styleNode-5 p {
    display: none
}
  

.styleNode-5 b {
    display: none
}

    .css-f6scpm {
        margin-top: 30px !important;
    }

    .css-oalq6z p {
       opacity: 1;
    }

    .ant-layout-content {
        margin: 0 !important;
        margin-top: 20px !important;
        padding: 0 !important;
    }

}

@media screen and (max-width: 539px) {

    .styleNode:hover + .subData {

         font-size: 10px;
        opacity: 1;
        line-height: 0;
         padding: 10px;
         padding-bottom: 15px;
         margin-bottom: 45px !important;
         display: block;
         flex-direction: column;
         /* display: inline-block; */
         position: fixed;
         background: #f5f5f5f6;
         box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
         border-radius: 5px;
         border: 1px solid #000000;
         z-index: 9999;
         width: 150px;
         height: 150px;
 }
 .styleNode-2:hover + .subData {
    font-size: 10px;
    opacity: 1;
    line-height: 1px;
     padding: 10px;
     padding-bottom: 15px;
     margin-bottom: 45px !important;
     display: block;
     flex-direction: column;
     /* display: inline-block; */
     position: fixed;
     background: #f5f5f5f6;
     box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
     border-radius: 5px;
     border: 1px solid #000000;
     z-index: 9999;
     width: 190px;
     height: 150px;
}

.styleNode-3:hover + .subData {
    font-size: 10px;
    opacity: 1;
    line-height: 1px;
     padding: 10px;
     padding-bottom: 15px;
     margin-bottom: 45px !important;
     display: block;
     flex-direction: column;
     /* display: inline-block; */
     position: fixed;
     background: #f5f5f5f6;
     box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
     border-radius: 5px;
     border: 1px solid #000000;
     z-index: 9999;
     width: 190px;
     height: 150px;
}

.styleNode-4:hover+.subData {
    font-size: 10px;
    opacity: 1;
    line-height: 1px;
     padding: 10px;
     padding-bottom: 15px;
     margin-bottom: 45px !important;
     display: block;
     flex-direction: column;
     /* display: inline-block; */
     position: fixed;
     background: #f5f5f5f6;
     box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
     border-radius: 5px;
     border: 1px solid #000000;
     z-index: 9999;
     width: 190px;
     height: 150px;
}

.styleNode-5:hover + .subData {
    font-size: 10px;
    opacity: 1;
    line-height: 1px;
     padding: 10px;
     padding-bottom: 15px;
     margin-bottom: 45px !important;
     display: block;
     flex-direction: column;
     /* display: inline-block; */
     position: fixed;
     background: #f5f5f5f6;
     box-shadow: -2px 2px 10px 3px rgba(0, 0, 0, 0.1);
     border-radius: 5px;
     border: 1px solid #000000;
     z-index: 9999;
     width: 190px;
     height: 150px;
   
}

    .container {
        margin-top: 0;
    }

    .ant-layout .ant-layout-has-sider{
        overflow: hidden;
    }

    .styleNode {
        padding: 5px;
     
        display: inline-block;
        border-radius: 8px;
        width: 100px;
        height: 100px;
    }

    .styleNode b {
        margin-top: -10px;
    }
    .styleNode p {
        margin-bottom: 0 !important;
    }

    .styleNode-2 {
        padding: 5px;
       
        display: inline-block;
        border-radius: 8px;
        width: 80px;
        height: 80px;
    }

    .styleNode-2 img {
        height: 38px !important;
        width: 38px !important;
    }

    .styleNode-2 svg { 

        margin-right: 20px;
    }

    .styleNode-3 {
        padding: 5px;
      
        display: inline-block;
        border-radius: 8px;
        width: 65px;
        height: 65px;
    }

    .styleNode-3 img {
        height: 30px !important;
        width: 30px !important;
    }

    .styleNode-3 svg { 

        margin-right: 12px;
    }

     .level3Name  {
    font-size: 6px !important;
}



    .styleNode-4 {
        padding: 5px;

        display: inline-block;
        border-radius: 8px;
        width: 42px;
        height: 42px;
    }
        .level4Name {
            opacity: 0 !important ;
        }



    .styleNode-4 img {
        height: 25px !important;
        width: 25px !important;
        margin-top: -3px !important;
    }

                .styleNode-4 svg {
                    color: black;
                    font-size: 16px;
                    opacity: .75;
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-right: 8px !important;
                    margin-top: 0px !important;
                }


    .styleNode-5 {
     
        display: inline-block;
        border-radius: 8px;
        width: 20px;
        height: 20px;
    }

    

.styleNode-5 .level5Name {
    opacity: 0 !important;
    font-size: 3px !important;
}



    .styleNode-5 img {
        height: 12px !important;
        width: 12px !important;
        /* margin-bottom: 15px; */
        margin: 0 0 9px 3px;
    }

    .styleNode-5 svg {
    color: black;
    font-size: 12px;
    opacity: .75;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -15px !important;
    margin-top: -12px !important;
        
}



    .css-f6scpm {
        margin-top: 30px !important;
    }

    .css-oalq6z p {
        opacity: 1;
    }

    .ant-layout-content {
        margin: 0 !important;
        margin-top: 0 !important;
        padding: 0 !important;
    }

}

@media screen and (max-width: 360px) {

    .styleNode-2 {
        padding: 5px;
        display: inline-block;
        border-radius: 8px;
        width: 70px;
        height: 80px;
    }
    .styleNode-3 {
        padding: 5px;
      
        display: inline-block;
        border-radius: 8px;
        width: 65px;
        height: 65px;
    }

    .styleNode-4 {
        padding: 5px;

        display: inline-block;
        border-radius: 8px;
        width: 42px;
        height: 42px;
    }

    .styleNode-5 {
     
        display: inline-block;
        border-radius: 8px;
        width: 20px;
        height: 20px;
    }

}
