@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap');

body {
  margin: 0;
font-family: 'Noto Sans Thai', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  
}

code {
font-family: 'Noto Sans Thai', sans-serif !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: black !important;
}