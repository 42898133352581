.App {
   text-align: center;
}

.text-underline{
   color: blue !important;
   position: relative !important;
   
}

.bg-white {
   background-color: #fff !important;
}

.black-color {
   color: black !important;
}

.secondary-color {
   color: #ffca00 !important;
}

/* Week Select */
.react-datepicker {
   font-family: 'Arial', sans-serif;
 }
 
 .react-datepicker__current-month {
   font-weight: bold;
   color: #007bff;
 }
 
 .react-datepicker__day--highlighted-week,
 .react-datepicker__day--highlighted-week:hover {
   background-color: #ffe082;
   color: #000;
   border-radius: 50%;
 }
 
 .highlight-week {
   background-color: #ffeb3b !important;
   color: #000 !important;
   border-radius: 50%;
   border: 1px solid #fdd835;
   transition: background-color 0.3s ease, color 0.3s ease;
 }
 
 .hover-highlight-week {
   background-color: #c8e6c9 !important;
   color: #000 !important;
   border-radius: 50%;
   border: 1px solid #a5d6a7;
   transition: background-color 0.3s ease, color 0.3s ease;
 }
 
 .react-datepicker__header {
   background-color: #f5f5f5;
   border-bottom: 1px solid #ddd;
 }
 
 .react-datepicker__day:hover {
   background-color: #e0e0e0;
   border-radius: 50%;
   transition: background-color 0.3s ease;
 }
 
 .custom-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px;
   background-color: #007bff;
   color: white;
   border-radius: 5px 5px 0 0;
 }
 
 .custom-header button {
   background: none;
   border: none;
   color: white;
   font-size: 16px;
   cursor: pointer;
 }
 
 .custom-header span {
   font-size: 16px;
 }
 
 .react-datepicker__day--selected {
   background-color: #007bff !important;
   color: white !important;
   border-radius: 50%;
   transition: background-color 0.3s ease, color 0.3s ease;
 }
 
 .react-datepicker__day--selected:hover {
   background-color: #0056b3 !important;
 }

.week-picker-display {
  width: 300px;
  height: 40px;
  color: #454545;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c3c0cc;
  transition: 0.5s ease;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.week-picker-options {
  padding: 0.4rem;
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-radius: 5px;
  border: 1px solid #c3c0cc;
  display: flex;
  overflow: hidden;
  background: white;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-week {
  padding: 0.8rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.numbers-container {
  border-top: 1px solid gainsboro;
  width: 100%;
  padding: 0.3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.single-number {
  width: 100%;
  aspect-ratio: 1;
  font-size: 0.8rem;
  background: none;
  color: black;
  display: grid;
  place-items: center;
}

.selected-week {
  color: white;
  background-color: #ff9900;
}

.other-month {
  background: none;
  color: #c3c0cc;
}

.day {
  background: none;
  color: black;
}

.arrow-container {
  cursor: pointer;
  width: 25px;
  height: 25px;
  transition: 0.2s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.arrow-container svg {
  stroke: #454545;
}

.arrow-container:hover {
  background-color: #ff9900;
  border-radius: 15%;
}

.arrow-container:hover svg {
  stroke: #fff;
  fill: #fff;
}

.other-month {
  background: none;
  color: #c3c0cc;
}


/* Week Select */

.ant-table-row-indent + .ant-table-row-expand-icon {
   /* background-color: red; */
}


.text-underline::before {
   content: '';
      position: absolute !important;
      width: 30%;
      height: 4px;
      border-radius: 4px;
      background-color: #18272F;
      bottom: 0 !important;
      left: 0 !important;
      transform-origin: right !important;
      transform: scaleX(0) !important;
      transition: transform .3s ease-in-out;
}

.text-underline:hover::before {
   transform-origin: left !important;
   transform: scaleX(1) !important;
}

button {
   transition: all 0.5s;
   border: none !important;
}
.ant-table-column-sorter {
   color: #ffca00 !important;
}

.ant-table-thead > tr > th {
   font-size: 18px;
   padding: 8px 16px;

   background-color: #14213D !important;
   color: #ffca00 !important

}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
   padding: 16px 6px !important;
   /* padding: 16px 16px; */
}


.ant-btn-primary {
   background-color: #14213D !important;
   color: white !important;
}

.btn-secondary {
   background-color: #ffca00 !important;
   color: #14213D !important;
}

.btn-orange {
   background-color: #ff5500 !important;
}

.btn-warning {
   line-height: 1.5715;
   position: relative !important;
   display: inline-block !important;
   font-weight: 400;
   white-space: nowrap !important;
   text-align: center !important;
   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
   user-select: none !important;
   touch-action: manipulation !important;
   height: 32px;
   padding: 4px 15px;
   font-size: 14px;
   border-radius: 2px !important;
   color: white !important;
   background-color: #ebad26 !important;
}

.btn-danger {
   line-height: 1.5715;
   position: relative !important;
   display: inline-block !important;
   font-weight: 400;
   white-space: nowrap !important;
   text-align: center !important;
   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
   user-select: none !important;
   touch-action: manipulation !important;
   height: 32px;
   padding: 4px 15px;
   font-size: 14px;
   border-radius: 2px !important;
   color: white !important;
   background-color: #eb2632 !important;
}

.btn-chooseProduct {
   padding: 20px;
   border-radius: 5px;
   background-color: #ff5500;
   color: white;
   width: 50%;
margin-left: auto;
margin-right: auto;
transition: all .3s;
}

.btn-chooseProduct:hover  {

   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

button:hover {
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.Login {
   background-image: url('./assets/img/5739655.jpg');
   height: 100vh;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

.auth-block {
   position: relative;
   /* top: 10%; */
   background-color: #fff;
   padding: 30px;
   margin-top: auto;
   margin-bottom: auto;
   /* margin: 10% 0; */
   max-width: 400px;
   width: 90%;
   height: 60%;
   /* position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%); */
}


@media screen and (max-width: 458px) {
  .auth-block {
   padding: 10px;
   height: 70%;
  }
}

@media screen and (max-width: 1024) {
   .auth-block {
    padding: 10px;
    height: 70%;
   }
 }

.hover-effect {
   
}

@media screen and (min-width: 860px) and (max-width: 1000px) { 
   .auth-block {
      position: relative;
      top: 5%;
      background-color: #fff;
      padding: 30px;
      margin: 10% 0;
      max-width: 400px;
      width: 90%;
      height: 60%;
      /* position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%,-50%); */
   }

}


.paid-row {
   background-color: rgba(255, 0, 0, 0.1) !important; /* Light red */
   color: red !important;
}

.form-block {
   background-color: #fff;
   padding-top: 50px !important;
   padding: 30px;
   margin: auto;
   max-width: 80vw;
   width: 100%;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form-block-deposit {
   background-color: #fff;
   padding-top: 50px !important;
   padding: 30px;
   padding-left: 100px;
   padding-right: 100px;
   margin: auto;
   max-width: 80vw;
   width: 100%;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form-block-transfer {
   background-color: #fff;
   padding-top: 50px !important;
   padding: 30px;
   margin: auto;
   max-width: 600px;
   width: 100%;
   
}

.form-block-register {
   background-color: #fff;
   padding-top: 50px !important;
   padding: 30px;
   margin: auto;
   max-width: 50%;
   width: 100%;
}

.form-block2 {
   /* background-color: #fff; */
   /* padding: 30px; */
   margin: auto;
   max-width: 1400;
   width: 100%;
   /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
}

/* 
@media screen and (max-width: 520px) {
  .ant-layout-header .trigger {
    display: none
  }
} */

.ant-layout {
   background: #f5f8ff  !important;
}

.ant-layout-header .trigger {
   font-size: 26px;
   transition: 0.3s all;
   cursor: pointer;
   color: #fff;
}
.ant-layout-header .trigger:hover {
   transform: scale(1.1);
}

@media screen and (max-width: 458px) {
   .ant-drawer-content-wrapper{
      width: 80% !important;
   }
   
   
   .ant-layout-header  {
    padding: 0 12px !important;
   }
   
   .ant-layout-header .ant-space-item .ant-badge {
      display: none !important;
   }
      .ant-layout-header .ant-space-item {
         font-size: 12px !important;
      }

}

.ant-form-item-label > label {
   font-size: 16px !important;
}
.ant-form-item {
   margin-bottom: 12px !important;
}

.site-layout .site-layout-background {
   /* background: #ebebeb; */
   background: #f5f8ff;
   /* box-shadow: 0 0 3px #ccc; */
   /* border-radius: 5px; */
}

.site-layout .site-layout-background-right {
   background: #fff;
   box-shadow: 0 0 3px #ccc;
   border-radius: 5px;
   width: 500px;
   max-width: 500px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
   color:  #ffca00 !important;
   background: #14213D !important;
}

.ant-layout-has-sider {
   /* padding: 15px !important; */
}

.ant-layout-sider {
   /* border-radius: 10px !important; */
   /* background: #f6d600 !important; */
   background: #14213D !important;
   /* height: 200vh; */
   /* height: 100vh; */
}

.ant-layout-sider-zero-width > * {
   opacity: 0 !important;
}

.ant-layout-sider-children {
   padding-top: 15px;
   /* padding-right: 20px; */
   height: 100vh !important;
   /* height: 95vh; */
}

@media screen and (min-width: 1445px) { 
   .form-block-register {
      max-width: 40%;
   }
}


@media screen and (max-width: 1440px) {
   .form-block-register {
      max-width: 50%;
   }
}
@media screen and (max-width: 1024px) {
   .form-block-register {
      max-width: 60%;
   }
}

@media screen and (max-width: 768px) {

   .form-block-register {
      max-width: 80%;
   }

}

@media screen and (max-width: 520px) {
   .ant-layout-has-sider {
      padding: 0 !important;
   }
   .form-block {
      padding-top: 30px !important;

    padding: 20px;
    max-width: 100vw;
   }
   .form-block-register {
      max-width: 95%;
   }
}

.anticon svg {
   height: 10px;
   width: 10px;
}

.ant-layout-header {
   /* margin: 0 10px !important; */
   display: flex;
   align-items: center;
   /* border-radius: 5px; */
   justify-content: space-between;
   background-color: #14213D !important;
}

.anticon svg {
   height: 20px;
   width: 20px;
   font-size: 20px;
}

.logo h3 {
   color: #047979 !important;
   font-size: 22px;
   font-weight: bold;
   text-align: center;
   padding-bottom: 100px;
}

.ant-menu-item-selected {
   /* border-top-right-radius: 8px;
   border-bottom-right-radius: 8px; */
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
   background-color: #f5f8ff  !important;
   border: 0.5px solid #ffca00;
   color: #14213D !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span {
  
   color: #14213D !important;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
   background-color: transparent !important;

   color: black !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg {
   color: black !important;
}

.ant-menu span {
   color: #fff !important;
}
.ant-menu a {
   color: #ffca00 !important;
}

.ant-menu-item:hover {
   background-color: transparent !important;
   color: #ffca00 !important;
}

.ant-menu-item:hover span {
   color: #ffca00 !important;
}
.ant-menu-item:hover a {
   color: #ffca00 !important;
}

.ant-menu {
   background: transparent !important;
   padding: 20px 0 !important;
   border-radius: 10px;
}
.ant-menu-sub.ant-menu-inline {
   padding: 0 !important;
}

.ant-menu-root.ant-menu-inline {
   /* box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px !important; */
}

.ant-menu-title-content a {
   color: rgb(228, 220, 220) !important;
}

.ant-menu-item-selected::after {
   opacity: 0 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
   border-right: none !important;
}

.ant-card-head-title svg {
   /* color: #f9d800 ; */
   font-size: 36px;
}

.ant-card-body {
   padding: 12px !important;
}

.ant-card {
   transition: all 0.5s ease;
   cursor: pointer;
}

/* .ant-card:hover {
   transform: scale(1.1);
} */

.drawer {
   width: 56px;
   height: 56px;
   padding: 0;
   position: fixed;
   bottom: 20px;
   right: 20px;
   z-index: 10;
   cursor: pointer;
   display: inline-block;
   transition: 0.3s ease-out;
   font-size: 1.6rem;
   text-align: center;
   border-radius: 50%;
   border: none;
   background-color: #eb2632 !important;
   color: white;
   overflow: hidden;
}

.ant-drawer-title {
   font-size: 30px !important;
   color: #38598b !important;
   border-bottom: 3px blue !important;
}

.ant-drawer-title img svg {
   color: rgb(2, 2, 2) !important;
   border-bottom: 3px blue !important;
}

.ant-dropdown-menu {
   width: 300px;
}

.ant-dropdown-menu-item-icon {
   margin-right: 20px !important;
   font-size: 24px !important;
   margin-bottom: 15px !important;
}

.ant-result .anticon svg {
   font-size: 75px !important;
   width: 75px !important;
   height: 75px !important;
}

.ant-statistic-content {
   font-size: 18px !important;
}

.typeahead {
}
.typeahead-address-label {
   padding: 10px 10px 10px 0;
   display: inline-block;
}

.typeahead-input-wrap input {
   width: 100%;
   padding: 4px;
   border: 1px solid #ccc;
   border-radius: 4px;
   resize: vertical;
}
.typeahead-input-hint {
   display: none;
}

.loading {
   background-color: transparent !important;
   height: 100vh !important;
}

/* .css-1kjun61 {
  width: 400px !important;

}


@media screen and (max-width: 520px) {
.css-1kjun61 {
    width: 250px !important;
    background-color: rgb(253, 130, 130) !important;
  }
} */

.cursor-pointer {
   cursor: pointer;
}

.cursor-pointer2 {
   cursor: pointer;
}

.register__permission {
      max-height: 600px;
         padding: 1em;
         margin: 0.5em 0;
         position: relative;
         overflow: auto;
         word-spacing: .5rem;

         color: black;
         font-size: 16px;
    
}

.register__permission .list-number {
   color: red;
   margin-right: 5px;
}